/* eslint-disable */
import React, { useContext, useState,useEffect } from "react";
import AuthContext from "../context/AuthContext";
import { Link as RouterLink } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-scroll";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { fogotPassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Déclenchement du loader
    try {
      await fogotPassword(email); // Attendre que l'appel de la fonction soit terminé
      setEmail("");
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  useEffect(() => {
    document.title = "Bousanm | Forgot Password";
  }, []);

  return (
    <section className="bg-light min-vh-100 d-flex flex-column">
      <div className="container-fluid bg-light py-2 d-flex justify-content-center align-items-center" id="navbar">
        <div className="container text-center d-flex justify-content-between align-items-center">
          <div>
            <a href="mailto:zig.bousanm@gmail.com" className="text-muted small">
              <i className="fas fa-envelope text-primary me-1"></i> zig.bousanm@gmail.com
            </a>
          </div>
          <div>
            <a href="#" className="text-muted small me-1">
              <i className="fa fa-clock text-primary me-0"></i> 7/7 | 24/24
            </a>
          </div>
          <div> 
            <a href="https://bousanm.com/" className="btn btn-primary btn-sm py-0">
              <i className="fas fa-home"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center align-items-center flex-grow-1">
        <div className="container">
          <div className="text-center mx-auto mt-3 pb-2" style={{ maxWidth: "700px" }}>
            <h4 className="display-7 text-primary">Mot de passe oublié</h4>
          </div>

          <div className="row g-5 justify-content-center align-items-center">
            <div className="col-xl-4 wow fadeInRight" data-wow-delay="0.4s">
              <div className="text-center mb-4">
                <img src="/img/logo.png" style={{ width: "90px" }} alt="Logo" />
              </div>

              <form onSubmit={handleSubmit}>
                <div className="row gy-4">
                  <div className="col-md-12">
                    <input
                      type="email"
                      className="form-control"
                      id="name"
                      name="email"
                      required
                      placeholder="Entrer votre Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" disabled={loading && "disabled"} className="btn btn-primary w-100">
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i> Envoie en cours...
                        </>
                      ) : (
                        <>Envoyer</>
                      )}
                    </button>
                  </div>
                  <div className="forgot-pwd mt-3 text-center">
                    <RouterLink className="pwd" to="/login">
                      Vous <span>connecter !</span>
                    </RouterLink>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-xl-4 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="d-flex justify-content-center">
                <img src="/img/forgot-img.png" className="img-fluid" alt="Image de mot de passe oublié" style={{ maxWidth: "100%", height: "auto" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
