import { useContext, useEffect, useState } from "react";
import { baseUrlTransactions } from "../../context/BaseUrl";
import AuthContext from "../../context/AuthContext";
const baseUrl = baseUrlTransactions;
const Timer = ({ clientId }) => {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [loading, setLoading] = useState(true);
    const {fetchWithAuth } = useContext(AuthContext);
  
    useEffect(() => {
      const fetchTimeUntilNextRun = async () => {
        try {
          const cleanedClientId = clientId.trim();  // Supprimer les espaces supplémentaires
          const response = await fetchWithAuth(`${baseUrl}next/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ client_id: cleanedClientId }),  // Utiliser le client_id nettoyé
          });
  
          if (response.ok) {
            const data = await response.json();
            setTimeLeft({
              hours: data.hours,
              minutes: data.minutes,
              seconds: data.seconds,
            });
          } else {
            console.error('Error fetching data:', response.statusText);
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchTimeUntilNextRun();
    }, [clientId,fetchWithAuth]);
  
    useEffect(() => {
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const { hours, minutes, seconds } = prevTime;
  
          if (seconds > 0) {
            return { hours, minutes, seconds: seconds - 1 };
          } else if (minutes > 0) {
            return { hours, minutes: minutes - 1, seconds: 59 };
          } else if (hours > 0) {
            return { hours: hours - 1, minutes: 59, seconds: 59 };
          } else {
            clearInterval(timerInterval);  // Stop the timer when it reaches 0
            return { hours: 0, minutes: 0, seconds: 0 };
          }
        });
      }, 1000);
  
      return () => clearInterval(timerInterval);  // Cleanup the interval on unmount
    }, [timeLeft]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      
      <div>
          {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds}
      </div>
    );
  };
  
  export default Timer;
  