import React, { useState } from "react";
import Sidebar from "../../Include/Sidebar";
import "./form.css";
import { whatsAppBusinessService } from "../../../services/whatsappBusinessService";

function Transfert() {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  // Fonction pour formater le numéro de téléphone
  const formatPhoneNumber = (number) => {
    // Supprimer tous les caractères non numériques
    const cleaned = number.replace(/\D/g, '');
    // Ajouter le préfixe 509 si ce n'est pas déjà fait
    return cleaned.startsWith('509') ? cleaned : `509${cleaned}`;
  };

  // Fonction pour envoyer l'OTP via Firebase
  const sendOtp = async () => {
    if (!phoneNumber) {
      alert("Veuillez entrer un numéro de téléphone");
      return;
    }

    if (phoneNumber.length < 8) {
      alert("Veuillez entrer un numéro de téléphone valide");
      return;
    }

    setLoading(true);
    try {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      
      // Envoyer l'OTP via WhatsApp
      const response = await fetch('http://votre-api.com/api/whatsapp-otp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: formattedNumber })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi du code');
      }

      alert("Code envoyé par WhatsApp !");
      setStep(2);
    } catch (error) {
      console.error("Erreur Firebase:", error);
      alert(`Erreur lors de l'envoi du code: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour vérifier l'OTP
  const verifyOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://votre-api.com/api/otp/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: formatPhoneNumber(phoneNumber), otp })
      });

      if (response.ok) {
        alert("Vérification réussie !");
        setStep(3);
      } else {
        alert("Code invalide. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur de vérification :", error);
      alert("Code invalide. Veuillez réessayer.");
    }
    setLoading(false);
  };

  return (
    <>
      <Sidebar />
      <main className="app-main">
        <div className="app-content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">Gestion des Transferts</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="app-content">
          <div className="container-fluid">
            <div className="container">
              <div className="col-md-12">
                <div className="card p-4">
                  {step === 1 && (
                    <div>
                      <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Numéro de téléphone (ex: 509XXXXXXXX)"
                        className="form-control"
                      />
                      <button 
                        className="btn btn-primary btn-sm mt-2" 
                        onClick={sendOtp} 
                        disabled={loading}>
                        {loading ? "Envoi en cours..." : "Envoyer OTP"}
                      </button>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Entrez OTP"
                        className="form-control"
                      />
                      <button className="btn btn-success btn-sm mt-2" onClick={verifyOtp} disabled={loading}>
                        {loading ? "Vérification..." : "Vérifier OTP"}
                      </button>
                    </div>
                  )}

                  {step === 3 && <h4>OTP Vérifié avec succès ! 🎉</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Transfert;
