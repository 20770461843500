/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import "./loopple.css";
import "./theme.css";
import { Alert } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import LoaderData from "../../Include/loaderData/loaderData";
import DataTable from "react-data-table-component";
import { customStyles } from "../../data/Utility";
import { Modal, Button, Form } from "react-bootstrap"; // Importer les composants Bootstrap
import { AccountContext } from "../../context/AccountsContext";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage"; // Fonction utilitaire pour extraire l'image recadrée
import { BaseUrlPhoto, BaseUrlPhotoClient } from "../../context/BaseUrl";
import { FormCustomers } from "../../data/FormCustomers";
import { TransactionsContext } from "../../context/TransactionsContext";
import Timer from "./Timer";
import { useHistory, useLocation } from "react-router-dom";
const swal = require("sweetalert2");

const ProfilSol = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAccountInt, setSelectedAccountInt] = useState(null);
  // const { timeLeft, fetchTimerData, loading1 } = useTimer();
  // const { getAccountDetailByCode } = useContext(CustomerContext);
  const {
    changePasswordCustomer,
    getAccountDetailByCode,
    getAccountIdByUsername,
    getAccountCodeListById,
    updateImageClient,
  } = useContext(AccountContext);

  const { CalculateInt, transactionsData, fetchTransactionsData } = useContext(TransactionsContext);

  const {
    getUserUsernameFromLocalStorage,
    logoutUser,
    getUserRoleFromLocalStorage,
    getUserImgFromLocalStorage,
  } = useContext(AuthContext);
  const username = getUserUsernameFromLocalStorage();
  const img = getUserImgFromLocalStorage();
  const role = getUserRoleFromLocalStorage();
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Pour stocker le message d'erreur
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [showModal, setShowModal] = useState(false); // Pour contrôler l'affichage du modal
  const [showModal1, setShowModal1] = useState(false); // Pour contrôler l'affichage du modal
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    document.title = "Bousanm | Profil";
    const fetchAccountInfo = async () => {
      setLoading(true);
      try {
        console.log(username);

        const client_id = await getAccountIdByUsername(username);
        console.log(client_id);

        const dataList = await getAccountCodeListById(client_id.client_id);
        setDataList(dataList);
        // console.log(dataList[0].code);
        // fetchTimerData(client_id);
        if (dataList[0]) {
          const data = await getAccountDetailByCode(dataList[0]?.code);
          if (!data.error) {
            setSelectedAccount(data);
            const intes = await CalculateInt(data?.id || 0);
            setSelectedAccountInt(intes);
            // Vérifielogr si les transactions existent avant de les définir dans records

            if (data.id) {
              fetchTransactionsData(data?.id || "");
            }
            // console.log("dt: ",transactionsData);
            
            if (data.historique_transactions) {
              setRecords(data.historique_transactions);
            } else {
              setRecords([]); // Si aucune transaction n'existe
            }
          } else {
            // alert("Erreur lors de la récupération des données du compte.");
          }
        }
      } catch (error) {
        alert(`Erreur: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountInfo();
    const handleClickOutside = (event) => {
      const sidebarElement = document.getElementById("sidenav-1-main");
      if (sidebarElement && !sidebarElement.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    username,
    getAccountDetailByCode,
    getAccountIdByUsername,
    getAccountCodeListById,
    // fetchTransactionsData,
    // fetchTimerData,
  ]);

  
  const columnsHistory = [
    {
      name: "TxID",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row?.montant,
      sortable: true,
    },
  ];

  // Ajouter conditionnellement la colonne "Date Sol"
  if (selectedAccount?.type_compte === "SOL") {
    columnsHistory.splice(3, 0, {
      name: "Date",
      selector: (row) => row?.date_sol,
      sortable: true,
    });
  } else {
    columnsHistory.splice(3, 0, {
      name: "Date",
      selector: (row) => row?.date,
      sortable: true,
    });
  }
  const customerId = selectedAccount?.client;
  const derniereTransaction = selectedAccount?.transaction;

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // Fonction pour soumettre le mot de passe
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (newPassword !== confirmPassword) {
      setPasswordError("Les mots de passe ne correspondent pas.");
      setLoading(false);
      return;
    }

    // Logique de changement de mot de passe

    try {
      await changePasswordCustomer(username, newPassword, confirmPassword);
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }

    // Réinitialiser les champs et fermer le modal
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setShowModal(false);
  };

  // Réinitialiser les champs et l'erreur à la fermeture du modal
  const handleCloseModal = () => {
    setShowModal(false);
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };
  const handleCloseModal1 = () => {
    setShowModal1(false);
  };
  const handleAccountClick = async (accountCode) => {
    setLoading(true); // Activez le chargement
    try {
      const data = await getAccountDetailByCode(accountCode); // Récupère les détails du compte
      if (!data.error) {
        setSelectedAccount(data); // Met à jour `selectedAccount` avec les données
        const intes = await CalculateInt(data?.id || 0);
        setSelectedAccountInt(intes);
        if (data.id) {
          fetchTransactionsData(data?.id || "");
        }

        setRecords(data.historique_transactions || []); // Met à jour les transactions ou une liste vide
      } else {
        swal.fire({
          title: "Erreur",
          text: "Impossible de récupérer les données du compte.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      swal.fire({
        title: "Erreur",
        text: `Erreur: ${error.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false); // Désactivez le chargement
    }
  };

  // Vérifiez les rôles de l'utilisateur
  const isCustomer = role === "User";
  const isOnlineUser = role === "TempUser";

  const [showModalPhoto, setShowModalPhoto] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1); // Permet de zoomer/dézoomer l'image
  const [cropArea, setCropArea] = useState(null);

  // Gérer le changement d'image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setShowModalPhoto(true);
    }
  };

  // Fermer le modal
  const handleCloseModalPhoto = () => {
    setShowModalPhoto(false);
    setSelectedImage(null);
  };
  // Appliquer le recadrage et l'enregistrer
  const handleCropComplete = async () => {
    if (selectedImage && cropArea) {
      const croppedImageUrl = await getCroppedImg(selectedImage, cropArea);
      setCroppedImage(croppedImageUrl); // Recadrer l'image

      // Créer un FormData pour envoyer l'image recadrée
      const formData = new FormData();
      const blob = await fetch(croppedImageUrl).then((r) => r.blob()); // Convertir l'image recadrée en blob
      formData.append("image", blob, "image.jpg"); // Ajouter l'image recadrée à FormData

      setLoading(true);
      try {
        await updateImageClient(customerId, formData); // Mettre à jour le client avec l'image recadrée
        swal.fire("Succès!", "Photo mise à jour avec succès!", "success");
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        swal.fire(
          "Erreur",
          "Une erreur est survenue lors de la mise à jour de la photo.",
          "error"
        );
      } finally {
        setLoading(false);
      }

      // Fermer le modal après l'enregistrement
      setShowModalPhoto(false);
    }
  };
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = () => {
    localStorage.removeItem("authTokens");
    
    // Rediriger vers le site avec un paramètre de requête pour indiquer le scroll
    window.location.href = "https://bousanm.com/?scrollTo=contact";
  };

  return (
    <div>
      <nav
        // className="sidenav-1 navbar-1 navbar-1-vertical fixed-left navbar-1-expand-xs navbar-1-light bg-white-1 loopple-fixed-start"
        // id="sidenav-1-main"
        className={`sidenav-1  navbar-1 navbar-1-vertical fixed-left 
          navbar-1-expand-xs navbar-1-light bg-white-1 loopple-fixed-start ${
            isSidebarVisible ? "active" : ""
          }`}
        id="sidenav-1-main"
      >
        <div className="navbar-1-inner">
          <button
            className="navbar-toggler d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#sidenav-1-collapse-main"
            aria-controls="sidenav-1-collapse-main"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleSidebar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-1-collapse"
            id="sidenav-1-collapse-main"
          >
            <ul className="navbar-1-nav">
              <li className="nav-item-1 mb-3 mt-0">
                <a
                  className="nav-link-1 active btn btn-outline-primary"
                  href="javascript:"
                >
                  {/* <i className="fa fa-desktop text-primary">
                  </i> */}
                  <img
                    src="/img/logo.png"
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                  {/* <span className="nav-link-1-text fw-bold float-end">.,</span> */}
                </a>
              </li>
              <li className="nav-item-1">
                <a className="nav-link-1 active fw-bold" href="javascript:">
                  <i className="fa fa-desktop text-primary"></i>
                  <span className="nav-link-1-text fw-bold">Vos Comptes</span>
                </a>
              </li>
              {dataList?.map((account, index) => (
                <li key={index} className="nav-item-1">
                  <a
                    className=" ml-3 "
                    onClick={() => handleAccountClick(account.code)} // Gestionnaire d'événement
                  >
                    <span className="nav-link-1-text btn btn-secondary btn-sm mt-2 px-5 py-1 fw-bold text-start ">
                      <i className="fas fa-wallet text-success me-1"></i>{" "}
                      {account.code}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <div className="main-content-1" id="panel">
        <nav
          className="navbar-1 navbar-1-top navbar-1-expand navbar-1-dark border-bottom bg-primary"
          id="navbar-1Top"
        >
          <div className="container-fluid-1">
            <div
              className="collapse navbar-1-collapse"
              id="navbar-1SupportedContent"
            >
              <ul className="navbar-1-nav align-items-center ml-md-auto">
                <li className="nav-item-1 d-xl-none">
                  <div
                    className="pr-3 sidenav-1-toggler sidenav-1-toggler-dark active"
                    onClick={toggleSidebar}
                  >
                    <div className="sidenav-1-toggler-inner">
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="navbar-1-nav align-items-center ml-auto ml-md-0">
                {isOnlineUser && (
                  <>
                    <Alert
                      className="nav-link-1 pr-0 alert-animation text-danger bg-white rounded mt-0 mb-0 py-2 px-3 d-flex align-items-center float-start"
                      variant="danger"
                    >
                      <i className="fas fa-exclamation-circle text-danger mr-2"></i>
                      <div>
                        <strong>Compte non validé :</strong>{" "}
                        <span
                          onClick={handleRedirect}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "red",
                          }}
                        >
                          Contactez le service client.
                        </span>
                      </div>
                    </Alert>
                  </>
                )}

                <li className="nav-item-1 dropdown float-start">
                  <a
                    className="nav-link-1 pr-0"
                    href="#"
                    role="button"
                    onClick={toggleDropdown}
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                  >
                    <div className="media align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        {loading ? (
                          // Afficher l'icône de spinner pendant le chargement
                          <i
                            className="fas fa-spinner fa-spin"
                            style={{ fontSize: "24px" }}
                          ></i>
                        ) : (
                          <img
                            src={
                              selectedAccount?.image
                                ? `${BaseUrlPhotoClient}${selectedAccount.image}`
                                : `${BaseUrlPhoto}/media/default.jpg`
                            }
                            alt="Avatar"
                            onError={(e) => {
                              e.target.src = `${BaseUrlPhoto}/media/default.jpg`;
                            }}
                          />
                        )}
                      </span>
                      <div className="media-body ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {username || "Nom Utilisateur"}
                        </span>
                      </div>
                    </div>
                  </a>
                  <div
                    className={`dropdown-menu dropdown-menu-right ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    <div className="dropdown-header">
                      <label className="text-overflow m-0 btn btn-light btn-sm py-0">
                        <input
                          type="file"
                          accept="image/*"
                          className="d-none"
                          onChange={handleImageChange} // Appeler la fonction handleImageChange
                        />
                        <i className="fas fa-camera"></i> Photo profil
                      </label>
                    </div>
                    <a
                      href="#!"
                      onClick={() => setShowModal(true)}
                      className="dropdown-item"
                    >
                      <i className="fas fa-lock"></i>
                      <span>Changer mot de passe</span>
                    </a>
                    <a
                      href="#!"
                      onClick={() => setShowModal1(true)}
                      className="dropdown-item"
                    >
                      <i className="fas fa-user-edit"></i>
                      <span>Modifier informations personnel</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={logoutUser} className="dropdown-item">
                      <i className="fas fa-sign-out-alt"></i>
                      <span>Déconnecter</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid-1 pt-3">
          {/* Autres contenus */}

          {/* Modal pour changer le mot de passe */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Changer le mot de passe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formNewPassword">
                  <Form.Label>Nouveau mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Entrez le nouveau mot de passe"
                  />
                </Form.Group>

                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirmer le mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmez le mot de passe"
                    isInvalid={!!passwordError} // Style si erreur
                    style={{
                      borderColor: passwordError ? "red" : "",
                    }}
                  />
                  {passwordError && (
                    <Form.Text style={{ color: "red" }}>
                      {passwordError}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="btn btn-danger btn-sm"
                onClick={handleCloseModal}
              >
                Fermer
              </Button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-sm"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> Modification en
                    cours...
                  </>
                ) : (
                  <>Modifier</>
                )}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={showModal1} onHide={handleCloseModal1} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Modifier votre profil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormCustomers customerId={customerId} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="btn btn-danger btn-sm"
                onClick={handleCloseModal1}
              >
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal pour afficher et recadrer l'image */}
          <Modal
            show={showModalPhoto}
            onHide={handleCloseModalPhoto}
            dialogClassName="modal-top" // Utilisation d'une classe personnalisée pour appliquer un style spécifique
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Recadrer la photo</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }} // Ajustez la hauteur du modal
            >
              {selectedImage && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center", // Centrer le Cropper horizontalement
                    alignItems: "center", // Centrer le Cropper verticalement
                  }}
                >
                  <Cropper
                    image={selectedImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // Forme carrée
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={(croppedArea, croppedAreaPixels) =>
                      setCropArea(croppedAreaPixels)
                    }
                    cropShape="rect" // Forme rectangulaire pour le cadre
                    showGrid={true} // Afficher une grille pour mieux visualiser le recadrage
                    style={{
                      containerStyle: {
                        maxWidth: "500px", // Limiter la taille du cadre
                        maxHeight: "300px", // Limiter la taille du cadre
                        width: "100%", // Rendre le Cropper responsive
                        height: "auto", // Conserver la hauteur naturelle de l'image
                      },
                      mediaStyle: {
                        width: "auto", // Utiliser la largeur maximale
                        height: "auto", // Conserver la hauteur naturelle de l'image
                      },
                      cropAreaStyle: {
                        borderColor: "#fff",
                        borderWidth: "2px",
                        borderStyle: "solid",
                      },
                    }}
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={loading}
                onClick={handleCropComplete}
              >
                {loading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> En cours...
                  </>
                ) : (
                  <>
                    <i className="fas fa-save"></i> Enregistrer
                  </>
                )}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {isCustomer && (
          <>
            <div className="container-fluid-1 pt-3">
              <div className="row removable">
                <div className="col-lg-4">
                  <div className="card-1 card-1-profile">
                    <img
                      src="/img/h-bg.png"
                      alt="Image placeholder"
                      className="card-1-img-top-1"
                    />
                    <div className="row justify-content-center">
                      <div className="col-lg-3 order-lg-2">
                        <div className="card-1-profile-1-image">
                          <a href="#">
                            {loading ? (
                              // Afficher l'icône de spinner pendant le chargement
                              <i
                                className="fas fa-spinner fa-spin"
                                style={{ fontSize: "30px" }}
                              ></i>
                            ) : (
                              <img
                                src={
                                  selectedAccount?.image
                                    ? `${BaseUrlPhotoClient}${selectedAccount.image}`
                                    : `${BaseUrlPhoto}/media/default.jpg`
                                }
                                className="rounded-circle"
                                alt="Avatar"
                                style={{width:"80px"}}
                                onError={(e) => {
                                  e.target.src = `${BaseUrlPhoto}/media/default.jpg`;
                                }}
                              />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-1-header text-center border-0 mt-4">
                      <div className="d-flex justify-content-between">
                        <a
                          href="#"
                          className="btn btn-sm mr-4 bg-primary p-1 fw-bold text-white"
                        >
                          Compte :{" "}
                          {selectedAccount
                            ? `${selectedAccount.type_compte}`
                            : "Type Compte"}
                          {selectedAccount?.type_compte === "SOL"
                            ? ` | ${selectedAccount?.sol} gdes`
                            : ""}
                          <span className="fw-normal fs-10"></span>
                        </a>

                        <a
                          href="#"
                          className="btn btn-sm float-right p-1 btn-success"
                        >
                          {selectedAccount
                            ? `${selectedAccount?.etat} `
                            : "etat"}{" "}
                          <span className="fw-normal fs-10"></span>
                        </a>
                      </div>
                    </div>
                    <div className="card-1-body pt-0">
                      <div className="row">
                        <div className="col">
                          <div className="text-center">
                            <h5 className="h3">
                              {selectedAccount
                                ? `${selectedAccount?.nom || ""} ${
                                    selectedAccount?.prenom || ""
                                  }`
                                : "Nom Complet"}
                              <hr className="m-0 bg-primary" />
                              <span className="font-weight-bold btn btn-outline-success text-success btn-sm mt-1 w-100">
                                Solde - {selectedAccount?.devise || ""}
                                {"    "}
                                {selectedAccount?.type_compte === "SOL"
                                  ? `    |    ${selectedAccount?.nb_main} main  `
                                  : ""}
                              </span>
                            </h5>
                            <h5></h5>
                          </div>
                          <div className="card-1-profile-1-stats">
                            <div className="row">
                              {selectedAccount?.solde_BS > 0 &&
                                selectedAccount?.type_compte != "SOL" && (
                                  <div
                                    className={`col-sm-${
                                      selectedAccount?.solde_M > 0 ? 6 : 12
                                    }`}
                                  >
                                    <div>
                                      <span className="heading bg-light rounded">
                                        {selectedAccount
                                          ? `${selectedAccount?.solde_BS || 0} `
                                          : "Balance"}
                                      </span>
                                      <span className="description">BS</span>
                                    </div>
                                  </div>
                                )}
                              <div className="col-sm-6">
                                {selectedAccount?.solde_M > 0 &&
                                  !selectedAccount?.type_compte != "SOL" && (
                                    <div>
                                      <span className="heading bg-light rounded">
                                        {selectedAccount
                                          ? `${selectedAccount?.solde_M || 0} `
                                          : "Solde Mutuelle"}
                                      </span>
                                      <span className="description">
                                        Mutuelle
                                      </span>
                                    </div>
                                  )}
                              </div>
                              {selectedAccount?.solde_PL > 0 &&
                                !selectedAccount?.type_compte != "SOL" && (
                                  <div className="col-sm-12">
                                    <div>
                                      <span className="heading  bg-light rounded">
                                        {selectedAccount
                                          ? `${selectedAccount?.solde_PL || 0} `
                                          : "Solde Placement"}
                                      </span>
                                      <span className="description">
                                        Placement
                                      </span>
                                    </div>
                                  </div>
                                )}

                              {selectedAccount?.type_compte === "SOL" && (
                                <div className="col-sm-12 mt-1">
                                  <div>
                                    <span className="heading bg-light rounded">
                                      {selectedAccount
                                        ? `${selectedAccount?.solde_S || 0} `
                                        : "Balance"}
                                    </span>
                                    <span className="description"> Sol</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedAccount?.type_compte === "SOL" && (
                  <>
                    <div className="col-lg-8">
                      {/* card-1 for Transactions .transactions[0].date*/}
                      <div className="card-1 card-1-stats">
                        <div className="card-1-body">
                          <div className="row">
                            <div className="col">
                              <h5 className="card-1-title text-uppercase text-muted mb-0">
                                Derniere Transaction
                              </h5>
                              <span className="h2 font-weight-bold mb-0">
                                {" "}
                                {selectedAccount
                                  ? `${
                                      selectedAccount?.derniere_transaction
                                        ?.montant_total || 0
                                    } `
                                  : "0"}
                              </span>{" "}
                              gdes
                            </div>
                            <div className="col-auto">
  <div className="icon shake icon-shape text-white rounded-circle shadow bg-warning text-sm fs-3">
    {selectedAccount ? (
      selectedAccount?.derniere_transaction?.type?.startsWith("dep") ? (
        <i className="fas fa-money-bill-wave"></i> // Icône pour un dépôt
      ) : selectedAccount?.derniere_transaction?.type?.startsWith("ret") ? (
        <i className="fas fa-exchange-alt"></i> // Icône pour un retrait
      ) : (
        <i className="fas fa-random"></i> // Icône pour une transaction des deux sens
      )
    ) : (
      <i className="fas fa-question-circle"></i> // Icône par défaut si aucune transaction n'est sélectionnée
    )}
  </div>
</div>

                          </div>
                          <p className="mt-3 mb-0 text-sm">
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up"></i>{" "}
                              {selectedAccount
                                ? `${
                                    selectedAccount?.derniere_transaction
                                      ?.date || ""
                                  } `
                                : "date "}
                            </span>
                            {/* <span className="text-nowrap">04:34:09 PM</span> */}
                          </p>
                        </div>
                      </div>
                      {/* card-1 for Interests nb_jours_payer*/}
                      <div className="card-1 card-1-stats">
                        <div className="card-1-body">
                          <div className="row">
                            <div className="col">
                              <h5 className="card-1-title text-uppercase text-muted mb-0">
                                Nombre de{" "}
                                {selectedAccount
                                  ? `${
                                      selectedAccount?.frequence_paiement || ""
                                    } `
                                  : "jours"}{" "}
                                payé
                              </h5>
                              <span className="h2 font-weight-bold mb-0">
                                {" "}
                                {selectedAccount
                                  ? `${selectedAccount?.nb_jours_payer || 0} `
                                  : "Jrs_payé"}
                              </span>
                            </div>
                            <div className="col-auto">
                              <h5 className="card-1-title text-uppercase text-muted mb-0 ">
                                Sur
                              </h5>
                              <div className="icon shake icon-shape text-white rounded-circle shadow bg-success fw-bold">
                                {selectedAccount
                                  ? `${selectedAccount?.total_jours || 0} `
                                  : "Jrs"}
                              </div>
                            </div>
                          </div>
                          <p className="mt-3 mb-0 text-sm">
                            <span className="text-success mr-2">
                              {/* <i className="fa fa-arrow-up"></i> */}
                            </span>
                            <span className="text-nowrap"></span>
                          </p>
                        </div>
                      </div>

                      <div class="card-1 card-1-stats">
                        <div class="card-1-body">
                          <div class="row">
                            <div className="col">
                              <h5 class="card-1-title text-uppercase text-muted mb-0">
                                Nombre de{" "}
                                {selectedAccount
                                  ? `${
                                      selectedAccount?.frequence_paiement || ""
                                    } `
                                  : "jours"}{" "}
                                non payé
                              </h5>
                              <span class="h2 font-weight-bold mb-0">
                                {" "}
                                {selectedAccount
                                  ? `${
                                      selectedAccount?.nb_jours_non_payer || 0
                                    } `
                                  : "Jrs"}
                              </span>{" "}
                            </div>
                            <div class="col-auto">
                              <div class="icon shake icon-shape bg-danger text-white rounded-circle shadow">
                                <i class="fa fa-inbox"></i>
                              </div>
                            </div>
                          </div>
                          <p class="mt-3 mb-0 text-sm">
                            <span class="text-danger mr-0">
                              <i class="fa fa-arrow-down"></i>&nbsp; Montant non
                              Payé:{" "}
                              {selectedAccount
                                ? `${selectedAccount?.montant_non_payer || 0} `
                                : "0"}
                            </span>
                            <span class="text-nowrap">Gourdes</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4  p-0">
                      <div className="card-1 card-1-profile p-0">
                        <div className="card-1-header text-center border-0 p-2">
                          <div className="d-flex justify-content-between">
                            <a
                              href="#"
                              className="btn btn-sm bg-primary fw-bold text-white w-100 fs-6"
                            >
                            <i class="fas fa-money-bill-wave shake me-1"></i>
                             Nombre de mains touché :{" "}
                              {transactionsData
                                ? `${
                                  transactionsData?.total_nb_main
                                       || 0
                                  }`
                                : "0"}
                            <i class="fas fa-money-bill-wave shake ml-2"></i>
                              <span className="fw-normal fs-10"></span>
                            </a>
                          </div>
                          <span className="float-end fw-bold text-muted me-2">Sur: 
                               {" "} {transactionsData
                                  ? `${transactionsData?.nb_main_compte || 0} `
                                  : ""}</span>

                        </div>
                        <div className="card-1-body p-0">
                          <div className="row p-0 ">
                            <div className="col-sm-12 p-0 ">
                             
                              <div className="card-1-profile-1-stats p-0  d-flex justify-content-center">
                                <div>
                                  <span className="description">
                                 <i className="fa fa-arrow-down"></i>{" "}  Montant Total Toucher:{""}
                                  
                                  </span>
                                  <span className="heading">
                                   
                                  {transactionsData
                                ? `${
                                  transactionsData?.total_montant
                                       || 0
                                  }`
                                : "0"} 
                                   <span className=" text-sm"> gdes</span>
                                  </span>{" "}
<span>
                          <p className="mt-3 mb-0 mt-1 text-sm float-end">
                            <span class="text-danger mr-0">
                              <i class="fa fa-arrow-right"></i> Différence :{" "}
                              {transactionsData
                                ? `${
                                  transactionsData?.solde_difference
                                       || 0
                                  }`
                                : "0"}
                            </span>
                            <span class="text-nowrap"> HTG</span>
                          </p>

</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-8">
                  {selectedAccount?.solde_PL > 0 &&
                    !selectedAccount?.type_compte != "SOL" && (
                      <div className="col-sm-12">
                        <div className="card-1 card-1-stats">
                          <div className="card-1-body">
                            <div className="row">
                              <div className="col">
                                <h5 className="card-1-title text-uppercase text-muted mb-0">
                                  Total Interet
                                </h5>
                                <span className="h2 font-weight-bold mb-0">
                                  {" "}
                                  {selectedAccountInt
                                    ? `${selectedAccountInt?.interet || 0} `
                                    : "0"}
                                </span>{" "}
                                {selectedAccount?.devise || ""}
                              </div>
                              <div className="col-auto">
                                <div className="icon icon-shape text-white rounded-circle shadow bg-gradient-warning"></div>
                              </div>
                            </div>
                            <p className="mt-3 mb-0 text-sm">
                              <span className="text-success mr-2">
                                <i className="fa fa-arrow-up"></i> Prochain
                                interet dans :
                              </span>
                              <span className="text-success mr-2 float-end">
                                <i className="fa fa-dollar"></i>
                                Montant journalier :{" "}
                                <span className="text-nowrap fw-bold">
                                  {" "}
                                  {selectedAccountInt
                                    ? `${
                                        selectedAccountInt?.dailyInterest || 0
                                      } `
                                    : "0"}
                                  {selectedAccount?.devise || ""}
                                </span>
                              </span>
                              <span className="text-nowrap">
                                <Timer
                                  clientId={
                                    selectedAccount
                                      ? `${selectedAccount?.id || 0} `
                                      : "temps"
                                  }
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="card-1">
                    <div className="card-1-header border-0">
                      <div className="row align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Historique</h3>
                        </div>
                        <div className="col text-right">
                          <a href="#!" className="btn btn-sm btn-primary">
                            <i className="fas fa-history"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <>
                          <LoaderData />
                        </>
                      ) : (
                        <>
                          <DataTable
                            columns={columnsHistory}
                            data={records}
                            fixedHeader
                            pagination
                            customStyles={customStyles}
                            paginationPerPage="5"
                            paginationRowsPerPageOptions={[5, 10, 30, 40, 50]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Lignes par page:",
                              rangeSeparatorText: "sur",
                              noRowsPerPage: false,
                              selectAllRowsItem: false,
                              selectAllRowsItemText: "Tous",
                            }}
                            noDataComponent="Aucune donnée trouvée"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Champs supplémentaires pour les administrateurs */}
        {isOnlineUser && (
          <>
            <div className="container-fluid-1 pt-3">
              <div className="row removable">
                <div className="col-lg-4">
                  <div className="card-1 card-1-profile">
                    <img
                      src="/img/h-bg.png"
                      alt="Image placeholder"
                      className="card-1-img-top-1"
                    />
                    <div className="row justify-content-center">
                      <div className="col-lg-3 order-lg-2">
                        <div className="card-1-profile-1-image">
                          <a href="#">
                            {loading ? (
                              // Afficher l'icône de spinner pendant le chargement
                              <i
                                className="fas fa-spinner fa-spin"
                                style={{ fontSize: "40px" }}
                              ></i>
                            ) : (
                              <img
                                src={
                                  selectedAccount?.image
                                    ? `${BaseUrlPhotoClient}${selectedAccount.image}`
                                    : `${BaseUrlPhoto}/media/default.jpg`
                                }
                                className="rounded-circle"
                                alt="Avatar"
                                style={{width:"80px"}}
                                onError={(e) => {
                                  e.target.src = `${BaseUrlPhoto}/media/default.jpg`;
                                }}
                              />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-1-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                      <div className="d-flex justify-content-between">
                        <a
                          href="#"
                          className="btn btn-sm mr-4 bg-primary fw-bold text-white"
                        >
                          Compte :{" "}
                          {selectedAccount
                            ? `${selectedAccount?.type_compte || ""}`
                            : "BS."}
                          <span className="fw-normal fs-10"></span>
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm float-right  fw-bold text-white btn-danger"
                        >
                          Inactif
                          <span className="fw-normal fs-10"></span>
                        </a>
                      </div>
                    </div>
                    <div className="card-1-body pt-0">
                      <div className="row">
                        <div className="col">
                          <div className="text-center">
                            <h5 className="h3">
                              {selectedAccount
                                ? `${selectedAccount?.nom || ""} ${
                                    selectedAccount?.prenom || ""
                                  }`
                                : "Nom Complet"}
                              <span className="font-weight-light"></span>
                            </h5>
                            <h5></h5>
                          </div>
                          <div className="card-1-profile-1-stats d-flex justify-content-center">
                            <div>
                              <span className="heading">
                                {selectedAccount
                                  ? `${selectedAccount?.balance || 0} `
                                  : "0"}
                                gdes
                              </span>
                              <span className="description">Solde BS</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="card-1">
                    <div className="card-1-header border-0">
                      <div className="row align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Historique des Transactions</h3>
                        </div>
                        <div className="col text-right">
                          <a href="#!" className="btn btn-sm btn-primary">
                            <i className="fas fa-history"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {loading ? (
                        <>
                          <LoaderData />
                        </>
                      ) : (
                        <div className="text-center fw-bold text-muted"> Aucune Transactions</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfilSol;
