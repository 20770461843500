class WhatsAppBusinessService {
    constructor() {
        // Votre token d'accès permanent de l'API WhatsApp Business
        this.accessToken = 'YOUR_ACCESS_TOKEN';
        // Votre ID de numéro de téléphone WhatsApp Business
        this.phoneNumberId = 'YOUR_PHONE_NUMBER_ID';
        // L'URL de base de l'API WhatsApp
        this.apiUrl = 'https://graph.facebook.com/v17.0';
    }

    // Générer un code OTP aléatoire
    generateOTP() {
        return Math.floor(100000 + Math.random() * 900000).toString();
    }

    // Envoyer un message WhatsApp
    async sendOTP(phoneNumber) {
        try {
            const otp = this.generateOTP();
            
            const response = await fetch(
                `${this.apiUrl}/${this.phoneNumberId}/messages`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${this.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        messaging_product: "whatsapp",
                        to: phoneNumber,
                        type: "template",
                        template: {
                            name: "otp_verification",
                            language: {
                                code: "fr"
                            },
                            components: [
                                {
                                    type: "body",
                                    parameters: [
                                        {
                                            type: "text",
                                            text: otp
                                        }
                                    ]
                                }
                            ]
                        }
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(`Erreur WhatsApp: ${response.status}`);
            }

            const data = await response.json();
            console.log('Message envoyé:', data);
            return otp;
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message:', error);
            throw error;
        }
    }

    // Vérifier le code OTP
    verifyOTP(inputOTP, generatedOTP) {
        return inputOTP === generatedOTP;
    }
}

export const whatsAppBusinessService = new WhatsAppBusinessService();
