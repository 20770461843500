import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import "./form.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";

function AddAcount() {
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [clientId, setClientId] = useState(null);
  const [clientData, setClientData] = useState({});
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // const [progressPercentage, setProgressPercentage] = useState(0);
  const {
    createClient,
    createAccount,
    CancelClient,
    ValidateClient,
  } = useContext(AccountContext);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    sexe: "",
    username: "",
    password: "",
    type_compte:"",
    devise:"",
    nb_main:"",
    sol:"",
    frequence_paiement:"",
    duree_sol:"",
    date_debut_sol:"",
  });

  const calculateProgress = () => {
    const fieldsFilled = Object.keys(formData).filter(
      (key) => formData[key] && formData[key] !== ""
    ).length;
    const totalFields = Object.keys(formData).length;
    return Math.round((fieldsFilled / totalFields) * 100);
  };

  const progressPercentage = calculateProgress();
  useEffect(() => {
    if (clientId && step === 2) {
      //console.log("Client ID prêt à être utilisé :", clientId);
      // Votre logique pour l'étape 2
    }
  }, [clientId, step]);
  
  const validateStep = async () => {
    const newErrors = {};
  let id ="";
    if (step === 1) {
      // Validations pour l'étape 1
      if (!formData.nom) {
        newErrors.nom = "Le champ nom est obligatoire.";
      }
      if (!formData.prenom) {
        newErrors.prenom = "Le champ prénom est obligatoire.";
      }
      if (!formData.tel) {
        newErrors.tel = "Le numéro de téléphone est obligatoire.";
      } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.tel)) {
        newErrors.tel = "Le numéro de téléphone n'est pas valide.";
      }
      if (!formData.sexe) {
        newErrors.sexe = "Le champ sexe est obligatoire.";
      }
      if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "L'email n'est pas valide.";
      }
  
      if (Object.keys(newErrors).length === 0) {
        const {
          nom,
          prenom,
          tel,
          email,
          date_naissance,
          lieu_naissance,
          sexe,
          adresse,
        } = formData;
  
        let formData1 = new FormData();
        formData1.append("nom", nom);
        formData1.append("prenom", prenom);
        formData1.append("email", email);
        formData1.append("tel", tel);
        formData1.append("date_naissance", date_naissance);
        formData1.append("lieu_naissance", lieu_naissance);
        formData1.append("sexe", sexe);
        formData1.append("adresse", adresse);
        formData1.append("created_by", user.user_id);
  
        try {
          setLoading(true);
          const response = await createClient(formData1);
          if (response.success) {
            setClientId(response.data.id); // Stockez l'ID client directement
            setStep(step + 1);
          } else {
            Object.keys(response.error).forEach((key) => {
              newErrors[key] = response.error[key][0];
            });
          }
        } catch (error) {
          newErrors.general = "Erreur lors de l'enregistrement.";
        } finally {
          setLoading(false);
        }
      } else {
        setErrors(newErrors);
      }
    }
 else if (step===2){
  if (!clientId) {
    console.error("L'ID client est requis pour l'étape 2.");
    return;
  }
      if (!formData.type_compte)
        newErrors.type_compte = "Le type de compte est requis.";
      if (!formData.devise) newErrors.devise = "La devise est requise.";

      if (Object.keys(newErrors).length === 0) {
  try {
    const {
      type_compte,
      devise,
      nb_main,
      sol,
      frequence_paiement,
      duree_sol,
      date_debut_sol,
    } = formData;

    setLoading(true); // Activer le loader
    let formData2 = new FormData();
    //console.log("Les données du client id:", id);
    formData2.append("client_id", id);
    formData2.append("type_compte", type_compte);
    formData2.append("devise", devise);
    formData2.append("nb_main", nb_main);
    formData2.append("sol", sol);
    formData2.append("frequence_paiement", frequence_paiement);
    formData2.append("duree_sol", duree_sol);
    formData2.append("date_debut_sol", date_debut_sol);
    formData2.append("created_by", user.user_id);
    formData2.append("email", clientData.email);
    formData2.append("username", clientData.username);
    formData2.append("password", clientData.pass_send);

    formData2.append("client_id", clientId); // Utilisez l'ID client ici
    const response = await createAccount(formData2);
    console.log("response client ID");
    
      await ValidateClient(clientId);
    if (response.id) {
      // Réinitialiser les données et revenir à l'étape 1
      // setFormData({});
      // setStep(1);
    } else {
      newErrors.general = "Erreur lors de la création du compte.";
    }
  } catch (error) {
    newErrors.general = "Erreur réseau ou serveur.";
  } finally {
    setLoading(false);
  }
} else {
  setErrors(newErrors);
}
}

setErrors(newErrors);
return newErrors;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Étape suivante
  const handleNext = async () => {
    // Attendez que la validation soit terminée
    const currentErrors = await validateStep();

    if (Object.keys(currentErrors).length === 0) {
      setStep(step + 1);

    } else {
      setErrors(currentErrors);
    }
  };

  // Étape suivante
  const handleVal = async () => {
    // Attendez que la validation soit terminée
    const currentErrors = await validateStep();

    if (Object.keys(currentErrors).length === 0) {
      setStep(1);

      setFormData({});
    } else {
      setErrors(currentErrors);
    }
  };

  // Étape précédente
  const handlePrev = async () => {
    if (step > 1 && clientId) {
      await CancelClient(clientId);
      setStep(step - 1);
      // setProgressPercentage(0);
    }
  };

  const handleValidate = async () => {
    // let currentErrors = {};

    // if (step === 1) {
    //   // Valider les champs pour l'étape 1
    //   if (!formData.nom) currentErrors.nom = "Le nom est requis.";
    //   if (!formData.prenom) currentErrors.prenom = "Le prénom est requis.";
    //   if (!formData.email) currentErrors.email = "L'email est requis.";
    //   if (!formData.tel) currentErrors.tel = "Le téléphone est requis.";
    //   if (!formData.sexe) currentErrors.sexe = "Le sexe est requis.";
    //   if (!formData.date_naissance)
    //     currentErrors.date_naissance = "La date de naissance est requise.";
    //   if (!formData.lieu_naissance)
    //     currentErrors.lieu_naissance = "Le lieu de naissance est requis.";
    //   if (!formData.adresse) currentErrors.adresse = "L'adresse est requise.";
    // } else if (step === 2) {
    //   // Valider les champs pour l'étape 2
    //   if (!formData.type_compte)
    //     currentErrors.type_compte = "Le type de compte est requis.";
    //   if (!formData.devise) currentErrors.devise = "La devise est requise.";

    //   // Champs spécifiques pour le type "SOL"
    //   if (formData.type_compte === "SOL") {
    //     if (!formData.nb_main)
    //       currentErrors.nb_main = "Le nombre de mains est requis.";
    //     if (!formData.sol) currentErrors.sol = "Le sol est requis.";
    //     if (!formData.frequence_paiement)
    //       currentErrors.frequence_paiement =
    //         "La fréquence de paiement est requise.";
    //     if (!formData.duree_sol)
    //       currentErrors.duree_sol = "La durée du sol est requise.";
    //     if (!formData.date_debut_sol)
    //       currentErrors.date_debut_sol = "La date de début est requise.";
    //   }
    // }

    // if (Object.keys(currentErrors).length > 0) {
    //   setErrors(currentErrors);
    // } else {
    //   setErrors({});
    //   if (step === 1) {
    //     // Passer à l'étape suivante
    //     setStep(2);
    //   } else {
    //     // Soumettre les données si c'est la dernière étape
    //     handleSubmit();
    //   }
    // }
      // Attendez que la validation soit terminée
      const currentErrors = await validateStep();

      if (Object.keys(currentErrors).length === 0) {
        // setStep(step + 1);
  
      } else {
        setErrors(currentErrors);
      }
  };

  const handleSubmit = async () => {
    try {
      const {
        type_compte,
        devise,
        nb_main,
        sol,
        frequence_paiement,
        duree_sol,
        date_debut_sol,
      } = formData;

      setLoading(true); // Activer le loader
      let formData2 = new FormData();
      //console.log("Les données du client :", clientData);
      formData2.append("client_id", clientId);
      formData2.append("type_compte", type_compte);
      formData2.append("devise", devise);
      formData2.append("nb_main", nb_main);
      formData2.append("sol", sol);
      formData2.append("frequence_paiement", frequence_paiement);
      formData2.append("duree_sol", duree_sol);
      formData2.append("date_debut_sol", date_debut_sol);
      formData2.append("created_by", user.user_id);
      formData2.append("email", clientData.email);
      formData2.append("username", clientData.username);
      formData2.append("password", clientData.pass_send);

      const response = await createAccount(formData2);
      await ValidateClient(clientId);

      if (response.id) {
        setFormData({});
        setStep(1);
      } else {
        console.error("Erreur lors de la création du compte.");
      }
    } catch (error) {
      console.error("Erreur :", error);
    } finally {
      setLoading(false); // Désactiver le loader
    }
  };

  return (<>
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestion des Comptes</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/List">Comptes</Link>
                    </li>
                    <li className="breadcrumb-item">Nouveau Compte</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              <div className="container">
                <div className="col-md-12">
                  <div className="card p-4">
                    {/* Barre de progression */}
                    <div className="row g-1">
                      <div className="col-md-12">
                        <div className="progress-container d-flex align-items-center">
                          <div
                            className="progress flex-grow-1 progress-bar-animated"
                            style={{ height: "10px" }}
                          >
                            <div
                              className="progress-bar progress-bar-animated bg-primary"
                              role="progressbar"
                              style={{ width: `${progressPercentage}%` }}
                              aria-valuenow={progressPercentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {/* Texte optionnel, rendu invisible */}
                              <span className="visually-hidden">
                                {progressPercentage}%
                              </span>
                            </div>
                          </div>
                          {/* Texte de progression */}
                        </div>
                      </div>
                      <div
                        className="col-md-12 d-flex justify-content-end"
                        style={{ marginTop: -10 }}
                      >
                        <p className="progress-text mb-4">
                          {progressPercentage}% complété
                        </p>
                      </div>
                    </div>
                    {/* Barre de progression */}

                    {step === 1 && (
                      <div className="row g-2">
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="nom"
                            placeholder="Nom"
                            value={formData.nom}
                            onChange={handleChange}
                            id="validationDefault01"
                            className={`form-control form-control-sm ${
                              errors.nom && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.nom ? errors.nom : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="prenom"
                            placeholder="Prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                            id="validationDefault02"
                            className={`form-control form-control-sm  ${
                              errors.prenom && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.prenom ? errors.prenom : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`form-control form-control-sm  ${
                              errors.email && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.email ? errors.email : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <PhoneInput
                            inputStyle={{ width: "100%", height: "100%" }}
                            className="phone"
                            placeholder="Téléphone"
                            country={"ht"}
                            value={formData.tel}
                            id="validationDefault03"
                            onChange={(phone) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                tel: phone,
                              }))
                            }
                            inputclassName={`form-control form-control-sm  ${
                              errors.tel && "is-invalid"
                            }`}
                          />
                          <span className="error">
                            {errors.tel ? errors.tel : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <select
                            className={`form-control form-control-sm  ${
                              errors.sexe && "is-invalid"
                            }`}
                            id="validationDefault04"
                            name="sexe"
                            value={formData.sexe}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Sexe...
                            </option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                          </select>
                          <span className="error">
                            {errors.sexe ? errors.sexe : <br />}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="date"
                            name="date_naissance"
                            placeholder="Date Naissance"
                            value={formData.date_naissance}
                            onChange={handleChange}
                            className={`form-control form-control-sm  ${
                              errors.date_naissance && "is-invalid"
                            }`}
                            id="validationDefault06"
                          />
                          <span className="error">
                            {errors.date_naissance ? (
                              errors.date_naissance
                            ) : (
                              <br />
                            )}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="lieu_naissance"
                            placeholder="Lieu de Naissance"
                            value={formData.lieu_naissance}
                            onChange={handleChange}
                            className={`form-control form-control-sm  ${
                              errors.lieu_naissance && "is-invalid"
                            }`}
                            id="validationDefault07"
                          />
                          <span className="error">
                            {errors.lieu_naissance ? (
                              errors.lieu_naissance
                            ) : (
                              <br />
                            )}
                          </span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            name="adresse"
                            placeholder="Adresse"
                            value={formData.adresse}
                            onChange={handleChange}
                            className={`form-control form-control-sm ${
                              errors.adresse && "is-invalid"
                            }`}
                            id="validationDefault08"
                          />
                          <span className="error">
                            {errors.adresse ? errors.adresse : <br />}
                          </span>
                        </div>
                        <div className="col-md-12">
                          <button
                            style={{ width: "100%" }}
                            type="submit"
                            className="btn btn-primary btn-sm"
                            onClick={handleNext}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                                Validation en cours...
                              </>
                            ) : (
                              <>Suivant</>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <div>
                        <div className="row g-3">
                          {/* Champ Type Compte */}
                          <div className="col-md-4">
                            <label
                              htmlFor="validationDefault09"
                              className="form-label"
                            >
                              Type Compte
                            </label>
                            <select
                              className={`form-control form-control-sm ${
                                errors.type_compte && "is-invalid"
                              }`}
                              id="validationDefault09"
                              name="type_compte"
                              value={formData.type_compte}
                              onChange={handleChange}
                            >
                              <option value="">Choisissez...</option>
                              <option value="BS">Bousanm</option>
                              <option value="SOL">Sol</option>
                            </select>
                            <span className="error">
                              {" "}
                              {errors.type_compte ? errors.type_compte : <br />}
                            </span>
                          </div>
                          <div className="col-md-4">
                            <label
                              htmlFor="validationDefault10"
                              className="form-label"
                            >
                              Devise
                            </label>
                            <select
                              className={`form-control form-control-sm ${
                                errors.devise && "is-invalid"
                              }`}
                              id="validationDefault10"
                              name="devise"
                              value={formData.devise}
                              onChange={handleChange}
                            >
                              <option value="">Choisissez...</option>
                              <option value="HTG">Gourde</option>
                              <option value="USD">Dollars US</option>
                            </select>
                            <span className="error">
                              {errors.devise ? errors.devise : <br />}
                            </span>
                          </div>
                        </div>

                        {/* Afficher Nb Main et Sol si type_compte === "SOL" */}
                        {formData.type_compte === "SOL" && (
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label htmlFor="nb_main" className="form-label">
                                Nb Main
                              </label>
                              <input
                                type="number"
                                step="any" // Permet les valeurs décimales
                                className={`form-control form-control-sm ${
                                  errors.nb_main && "is-invalid"
                                }`}
                                id="nb_main"
                                name="nb_main"
                                value={formData.nb_main}
                                onChange={handleChange}
                              />
                              <span className="error">
                                {errors.nb_main ? errors.nb_main : <br />}
                              </span>
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="sol" className="form-label">
                                Sol
                              </label>
                              <input
                                type="number"
                                className={`form-control form-control-sm ${
                                  errors.sol && "is-invalid"
                                }`}
                                id="sol"
                                name="sol"
                                value={formData.sol}
                                onChange={handleChange}
                              />
                              <span className="error">
                                {errors.sol ? errors.sol : <br />}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="validationDefault10"
                                className="form-label"
                              >
                                Frequence paiement
                              </label>
                              <select
                                className={`form-control form-control-sm ${
                                  errors.frequence_paiement && "is-invalid"
                                }`}
                                id="validationDefault13"
                                name="frequence_paiement"
                                value={formData.frequence_paiement}
                                onChange={handleChange}
                              >
                                <option value="">Choisissez...</option>
                                <option value="jours">Jours</option>
                                <option value="semaine">Semaine</option>
                                <option value="mois">Mois</option>
                              </select>
                              <span className="error">
                                {errors.frequence_paiement ? (
                                  errors.frequence_paiement
                                ) : (
                                  <br />
                                )}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="validationDefault10"
                                className="form-label"
                              >
                                Duree sol
                              </label>
                              <input
                                type="number"
                                className={`form-control form-control-sm ${
                                  errors.duree_sol && "is-invalid"
                                }`}
                                id="duree_sol"
                                name="duree_sol"
                                value={formData.duree_sol}
                                onChange={handleChange}
                              />
                              <span className="error">
                                {errors.duree_sol ? errors.duree_sol : <br />}
                              </span>
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="validationDefault10"
                                className="form-label"
                              >
                                Date debut
                              </label>
                              <input
                                type="date"
                                className={`form-control form-control-sm ${
                                  errors.date_debut_sol && "is-invalid"
                                }`}
                                id="date_debut_sol"
                                name="date_debut_sol"
                                value={formData.date_debut_sol}
                                onChange={handleChange}
                              />
                              <span className="error">
                                {errors.date_debut_sol ? (
                                  errors.date_debut_sol
                                ) : (
                                  <br />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="row g-2 pl-3">

                        <span className="error">
                            {errors.general ? errors.general : <br />}
                          </span>
                          <div className="d-flex">
                            <button
                              className="btn btn-secondary me-1"
                              onClick={handlePrev}
                            >
                              Précédent
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                              onClick={handleVal}
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <i className="fas fa-spinner fa-spin"></i>{" "}
                                  Validation en cours...
                                </>
                              ) : (
                                <>Valider</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </>
  );
}

export default AddAcount;
